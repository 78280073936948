import {HTTP} from '../utils/axios'

class FlowModel extends HTTP {
  /*首页渲染*/
  index(date1 = '', date2 = '') {
    return this.request({
        url: 'board/app_flow_analyses',
        method: "GET",
        data: {
          date1: date1,
          date2: date2
        }
    })
  }
}

export {FlowModel}