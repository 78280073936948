<template>
  <div class="home">
    <a-row>
      <a-col :span="8">
        <a-row>
          <a-col :span="12">
            <div class="number">
              <a-icon type="laptop" />
              <span>软件总数</span>
              <span>{{ softwareNum | formatBigNumber }}</span>
              <router-link to="/board/softlists"> 查看 </router-link>
            </div>
          </a-col>

          <a-col :span="12">
            <div class="number">
              <a-icon type="file" />
              <span>文档总数</span>
              <span>{{ documentNum | formatBigNumber }}</span>
              <router-link to="/documents"> 查看 </router-link>
            </div>
          </a-col>
        </a-row>

        <a-row>
          <a-col :span="12">
            <div class="number">
              <a-icon type="user" />
              <span>会员总数</span>
              <span>{{ userNum | formatBigNumber }}</span>
            </div>
          </a-col>

          <a-col :span="12">
            <div class="number">
              <img src="@/assets/img/vip.png">
              <span>VIP会员总数</span>
              <span>{{ vipUserNum | formatBigNumber }}</span>
            </div>
          </a-col>

          <a-col :span="24" class="show-button">
            <router-link to="/memberManage"> 查看 </router-link>
          </a-col>
        </a-row>

        <a-row>
          <a-col :span="12">
            <div class="number">
              <a-icon type="money-collect" />
              <span>购买积分消费金额</span>
              <span>￥{{ total_amount }}</span>
            </div>
          </a-col>

          <a-col :span="12">
            <div class="number">
              <a-icon type="money-collect" />
              <span>订购会员总金额</span>
              <span>￥{{ total_subscribe_amount }}</span>
            </div>
          </a-col>
        </a-row>
      </a-col>

      <a-col :span="16">
        <a-tabs :active-key="currentRankingTab" @change="handleChangeRankingTab">
          <a-tab-pane key="software_ranking" tab="软件排行">
            <a-row>
              <a-col :span="8">
                <div>
                  <h5>
                    <span>下载量排行</span>
                  </h5>

                  <p v-for="(item, index) in download_num" :key="index">
                    <span>{{ item.name }}</span>
                    <span>{{ item.sum }}</span>
                  </p>
                </div>
              </a-col>

              <a-col :span="8">
                <div>
                  <h5>
                    <span>点赞量排行</span>
                  </h5>
                  <p v-for="(item, index) in like_count" :key="index">
                    <span>{{ item.name }}</span>
                    <span>{{ item.count }}</span>
                  </p>
                </div>
              </a-col>

              <a-col :span="8">
                <div>
                  <h5>
                    <span>收藏量排行</span>
                  </h5>
                  <p v-for="(item, index) in collectionCount" :key="index">
                    <span>{{ item.name }}</span>
                    <span>{{ item.collection_count }}</span>
                  </p>
                </div>
              </a-col>
            </a-row>
          </a-tab-pane>
          <a-tab-pane key="document_ranking" tab="文档排行" force-render>
            <a-row :gutter="24">
              <a-col :span="8">
                <div>
                  <h5>
                    <span>下载量排行</span>
                  </h5>

                  <p v-for="(item, index) in documentDownloadCountData" :key="index">
                    <span>{{ item.name }}</span>
                    <span>{{ item.download_num }}</span>
                  </p>
                </div>
              </a-col>

              <a-col :span="8">
                <div>
                  <h5>
                    <span>点赞量排行</span>
                  </h5>
                  <p v-for="(item, index) in documentLikeCountData" :key="index">
                    <span>{{ item.name }}</span>
                    <span>{{ item.like_num }}</span>
                  </p>
                </div>
              </a-col>

              <a-col :span="8">
                <div>
                  <h5>
                    <span>收藏量排行</span>
                  </h5>
                  <p v-for="(item, index) in documentCollectionCountData" :key="index">
                    <span>{{ item.name }}</span>
                    <span>{{ item.collection_count }}</span>
                  </p>
                </div>
              </a-col>
            </a-row>
          </a-tab-pane>
        </a-tabs>

        <a-row>
          <div>
            <a-tabs default-active-key="1" @change="handleChangeTab">
              <a-tab-pane key="1" tab="注册趋势">
                <a-form layout="inline" :form="form2">
                  <a-form-item>
                    <span
                      @click="handleUserTime(7)"
                      class="sevenDay"
                      :class="{ active: user_time == 7 }"
                      >最近7天</span
                    >
                  </a-form-item>
                  <a-form-item>
                    <span
                      @click="handleUserTime(30)"
                      class="halfMonth"
                      :class="{ active: user_time == 30 }"
                      >最近一个月</span
                    >
                  </a-form-item>
                  <a-form-item>
                    <a-range-picker
                      :disabled-date="disabledDate"
                      @change="handleUserChange"
                      locale
                      :default-value="[
                        moment(new Date(), dateFormat),
                        moment(new Date(), dateFormat),
                      ]"
                      autocomplete="off"
                    />
                  </a-form-item>
                </a-form>
                <ve-line
                  v-if="userChartData.rows.length > 0"
                  :data="userChartData"
                  height="320px"
                  :yAxis="yAxis"
                  :tooltip="tooltip"
                  :grid="grid"
                  :legend-visible="false"
                >
                </ve-line>
              </a-tab-pane>
              <a-tab-pane key="2" tab="购买积分消费趋势" force-render>
                <a-form layout="inline" :form="form3">
                  <a-form-item>
                    <span
                      @click="handleAmountTime(7)"
                      class="sevenDay"
                      :class="{ active: amount_time == 7 }"
                      >最近7天</span
                    >
                  </a-form-item>
                  <a-form-item>
                    <span
                      @click="handleAmountTime(30)"
                      class="halfMonth"
                      :class="{ active: amount_time == 30 }"
                      >最近一个月</span
                    >
                  </a-form-item>
                  <a-form-item>
                    <a-range-picker
                      :disabled-date="disabledDate"
                      @change="handleAmountChange"
                      locale
                      :default-value="[
                        moment(new Date(), dateFormat),
                        moment(new Date(), dateFormat),
                      ]"
                      autocomplete="off"
                    />
                  </a-form-item>
                </a-form>
                <ve-line
                  v-if="amountChartData.rows.length > 0"
                  :data="amountChartData"
                  height="320px"
                  :yAxis="yAxis"
                  :tooltip="tooltip"
                  :grid="grid"
                  :legend-visible="false"
                >
                </ve-line>
              </a-tab-pane>
              <a-tab-pane key="3" tab="流量日报分析" force-render>
                <a-form layout="inline" :form="form4">
                  <a-form-item>
                    <span
                      @click="handleFlowTime(7)"
                      class="sevenDay"
                      :class="{ active: flow_time == 7 }"
                      >最近7天</span
                    >
                  </a-form-item>
                  <a-form-item>
                    <span
                      @click="handleFlowTime(30)"
                      class="halfMonth"
                      :class="{ active: flow_time == 30 }"
                      >最近一个月</span
                    >
                  </a-form-item>
                  <a-form-item>
                    <a-range-picker
                      :disabled-date="disabledDate"
                      @change="handleFlowChange"
                      locale
                      :default-value="[
                        moment(new Date(), dateFormat),
                        moment(new Date(), dateFormat),
                      ]"
                      autocomplete="off"
                    />
                  </a-form-item>
                </a-form>
                <ve-line
                  v-if="flowChartData.rows.length > 0"
                  :data="flowChartData"
                  height="320px"
                  :yAxis="yAxis"
                  :tooltip="tooltip"
                  :grid="grid"
                  :legend-visible="true"
                  style="top: 10px"
                >
                </ve-line>
              </a-tab-pane>
            </a-tabs>
          </div>
        </a-row>
      </a-col>
    </a-row>
  </div>
</template>


<script>
import moment from "moment";
import locale from "ant-design-vue/es/date-picker/locale/zh_CN";
import "moment/locale/zh-cn";
import { SoftlistsModel } from "@/models/softlists";
import { MemberManageModel } from "@/models/memberManage";
import { PointRecordModel } from "@/models/pointRecord";
import { VipSubscribeOrderModel } from "@/models/vipSubscribeOrders";
import { FlowModel } from "@/models/flow";
import { DocumentModel } from "@/models/document.js"

const documentModel = new DocumentModel()
const softlistsModel = new SoftlistsModel();
const memberManageModel = new MemberManageModel();
const pointRecordModel = new PointRecordModel();
const flowModel = new FlowModel();
const vipSubscribeOrderModel = new VipSubscribeOrderModel();
moment.locale("zh-cn");
export default {
  data() {
    this.dateFormat = "YYYY-MM-DD";
    this.chartSettings = {
      xAxisType: "time",
    };

    this.tooltip = {
      show: false,
    };

    this.grid = {
      right: "3%",
    };

    this.yAxis = [
      {
        axisLabel: {
          show: true,
          textStyle: {
            color: "#333",
          },
        },
        minInterval: 1,
        splitNumber: 5,
        axisLine: {
          show: true, //是否显示Y轴线
        },
      },
    ];

    return {
      locale,
      form2: this.$form.createForm(this, {
        name: "coordinated",
      }),
      form3: this.$form.createForm(this, {
        name: "amount_time",
      }),
      form4: this.$form.createForm(this, {
        name: "flow",
      }),
      userChartData: {
        columns: ["sign_date", "total_num"],
        rows: [],
      },
      amountChartData: {
        columns: ["sign_date", "total_num"],
        rows: [],
      },
      flowChartData: {
        columns: ["day", "使用流量", "收费流量"],
        rows: [],
      },
      legend: {
        data: ["使用流量", "收费流量"],
      },
      softwareNum: 0,
      documentNum: 0,
      userNum: 0,
      vipUserNum: 0,
      // 购买积分消费金额
      total_amount: "",
      total_subscribe_amount: "",
      user_time: 7,
      amount_time: 7,
      flow_time: 7,
      download_num: [],
      like_count: [],
      collectionCount: [],
      documentDownloadCountData: [],
      documentLikeCountData: [],
      documentCollectionCountData: [],
      currentRankingTab: 'software_ranking'
    }
  },

  created() {
    this.fetchUserCount()
    this.fetchTotalAmount()
    this.fetchTotalSubscribeAmount()
    this.queryUsersList(7)
    this.fetchSoftWareData()
    this.fetchDocumentCount()
  },

  methods: {
    moment,

    disabledDate(current) {
      // Can not select days before today and today
      return current && current > moment().endOf("day");
    },

    fetchUserCount() {
      memberManageModel.index().then((res) => {
        if (res.code == 0) {
          this.userNum = res.total
          this.vipUserNum = res.vip_count
        }
      })
    },

    fetchTotalAmount() {
      pointRecordModel.totalAmount().then((res) => {
        if (res.code == 0) {
          this.total_amount = Number(res.total_amount).toFixed(2);
        }
      })
    },

    fetchTotalSubscribeAmount() {
      vipSubscribeOrderModel.totalAmount().then((res) => {
        if (res.code == 0) {
          this.total_subscribe_amount = Number(res.total_subscribe_amount).toFixed(2);
        }
      })
    },

    fetchSoftWareData() {
      softlistsModel.index("", "", "", "", "", "", "", "", "all").then((res) => {
        if (res.code == 0) {
          this.softwareNum = res.total
          // var objectArraySort = function (keyName) {
          //   return function (objectN, objectM) {
          //     var valueN = objectN[keyName];
          //     var valueM = objectM[keyName];
          //     if (valueN < valueM) return 1;
          //     else if (valueN > valueM) return -1;
          //     else return 0;
          //   };
          // };
          this.download_num = res.app_versions_data
          this.like_count = res.app_likes_data
          this.collectionCount = res.app_collection_count_data
        }
      })
    },

    fetchDocumentCount() {
      documentModel.documentsStatistics().then(res => {
        if (res.code == 0) {
          this.documentNum = res.total
          this.documentDownloadCountData = res.download_count_data
          this.documentLikeCountData = res.document_likes_data
          this.documentCollectionCountData = res.document_collections_data
        }
      })
    },

    handleChangeTab(key) {
      this.yAxis[0].name = "";
      this.tooltip = {
        show: false,
      };
      if (key === "1") {
        this.queryUsersList(7);
      } else if (key === "2") {
        this.queryAmountsList(7);
      } else if (key === "3") {
        this.queryFlowList(7);
      }
    },

    handleChangeRankingTab(key) {
      this.currentRankingTab = key
    },

    handleUserChange(date, dateString) {
      memberManageModel.time(dateString[0], dateString[1]).then((res) => {
        if (res.code == 0) {
          this.userChartData.rows = res.result;
        }
      });
    },

    handleAmountChange(date, dateString) {
      pointRecordModel
        .amountThread(dateString[0], dateString[1])
        .then((res) => {
          if (res.code == 0) {
            this.amountChartData.rows = res.result;
          }
        });
    },

    handleFlowChange(date, dateString) {
      this.flowChartData.rows = [];
      flowModel
        .index(
          new Date(dateString[0]).toISOString(),
          new Date(dateString[1]).toISOString()
        )
        .then((res) => {
          if (res.code == 0) {
            this.flowChartData.rows = res.result;
            this.flowChartData.rows.forEach((item) => {
              item["使用流量"] = Number(
                (item.flow / (1024 * 1024 * 1024)).toFixed(2)
              );
              item["收费流量"] = Number(
                (item.charge / (1024 * 1024 * 1024)).toFixed(2)
              );
            });
            this.yAxis[0].name = "GB";
            this.tooltip = {
              show: true,
            };
          }
        });
    },

    handleUserTime(n) {
      this.user_time = n;
      this.queryUsersList(n);
    },

    handleAmountTime(n) {
      this.amount_time = n;
      this.queryAmountsList(n);
    },

    handleFlowTime(n) {
      this.flow_time = n;
      this.queryFlowList(n);
    },

    queryUsersList(n) {
      let beginTime = "",
        endTime = "";
      let date = new Date();
      endTime =
        date.getFullYear() + "-" + (date.getMonth() + 1) + "-" + date.getDate();
      date.setTime(date.getTime() - n * 24 * 60 * 60 * 1000);
      beginTime =
        date.getFullYear() + "-" + (date.getMonth() + 1) + "-" + date.getDate();
      memberManageModel.time(beginTime, endTime).then((res) => {
        if (res.code == 0) {
          this.userChartData.rows = res.result;
        }
      });
    },

    queryAmountsList(n) {
      let beginTime = "",
        endTime = "";
      let date = new Date();
      endTime =
        date.getFullYear() + "-" + (date.getMonth() + 1) + "-" + date.getDate();
      date.setTime(date.getTime() - n * 24 * 60 * 60 * 1000);
      beginTime =
        date.getFullYear() + "-" + (date.getMonth() + 1) + "-" + date.getDate();
      pointRecordModel.amountThread(beginTime, endTime).then((res) => {
        if (res.code == 0) {
          this.amountChartData.rows = res.result;
        }
      });
    },

    queryFlowList(n) {
      let beginTime = "",
        endTime = "";
      let date = new Date();
      endTime =
        date.getFullYear() + "-" + (date.getMonth() + 1) + "-" + date.getDate();
      date.setTime(date.getTime() - n * 24 * 60 * 60 * 1000);
      beginTime =
        date.getFullYear() + "-" + (date.getMonth() + 1) + "-" + date.getDate();
      this.flowChartData.rows = [];
      flowModel
        .index(
          new Date(beginTime).toISOString(),
          new Date(endTime).toISOString()
        )
        .then((res) => {
          if (res.code == 0) {
            this.flowChartData.rows = res.result;
            this.flowChartData.rows.forEach((item) => {
              item["使用流量"] = Number(
                (item.flow / (1024 * 1024 * 1024)).toFixed(2)
              );
              item["收费流量"] = Number(
                (item.charge / (1024 * 1024 * 1024)).toFixed(2)
              );
            });
            this.yAxis[0].name = "GB";
            this.tooltip = {
              show: true,
            };
            console.log(this.flowChartData);
          }
        });
    },
  },
};
</script>


<style lang="scss" scoped>
.home .ant-col > div {
  background: #f2f2f2;
  margin-right: 12px;
  margin-bottom: 12px;
  padding: 10px 30px;
}

.home .ant-col:nth-of-type(3) > div,
.home .ant-col:nth-of-type(5) > div {
  margin-right: 0;
}

.home .ant-col h5 span {
  color: #3333337a;
  border-bottom: 3px solid #898a89;
  font-size: 18px;
  letter-spacing: 1px;
  font-weight: bold;
  padding-bottom: 6px;
}

.home .ant-col p {
  margin-top: 1.8em;

  & > span:nth-of-type(2) {
    float: right;
  }
}

.number {
  text-align: center;

  span {
    display: block;
    margin-bottom: 12px;
  }

  & > i:nth-of-type(1) {
    font-size: 30px;
    border-bottom: 1px solid #898a89;
    padding-bottom: 8px;
    margin-bottom: 12px;
    margin-top: 20px;
  }

  img {
    height: 39px;
    border-bottom: 1px solid #898a89;
    padding-bottom: 8px;
    margin-bottom: 12px;
    margin-top: 20px;
  }

  & > span:nth-of-type(1) {
    color: #3241577a;
    font-size: 16px;
  }

  & > span:nth-of-type(2) {
    font-size: 30px;
    color: #333;
  }

  a {
    width: 80%;
    height: 50px;
    line-height: 50px;
    border: 1px solid #333;
    display: inline-block;
    color: #333;
    margin-bottom: 20px;
  }
}

.show-button {
  text-align: center;
  a {
    width: 80%;
    height: 50px;
    line-height: 50px;
    border: 1px solid #333;
    display: inline-block;
    color: #333;
    margin-bottom: 20px;
  }
}

.ant-form-inline {
  text-align: left !important;
  margin-top: 0;
  margin-bottom: 10px;
  position: relative;
  z-index: 2;
}

.ve-line {
  top: -35px;
  z-index: 0;
}

.sevenDay,
.halfMonth {
  border: 1px solid #7e7e7e;
  padding: 5px 8px;
  cursor: pointer;
}

.active {
  background: #199ed8;
  color: #fff;
  border: 1px solid #199ed8;
}
</style>
